<template>
  <div style="background-color: #46AFEB; min-height: 100vh; padding-top: 2em;">
    <v-container style="background-color: #FFF;">
      <v-form
        v-if="isForm"
        @submit.prevent="submitForm"
      >
        <v-alert v-if="message" v-text="message"></v-alert>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field v-model="fullName" label="ФИО" outlined></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="email" label="Почта" outlined></v-text-field>
          </v-col>
        </v-row>

        <v-row v-for="(question, index) in questions" :key="index">
          <v-col cols="12" md="6">
            <v-slider
                v-model="question.rating"
                :label="question.label"
                :max="4"
                tick-size="5"
                thumb-label
            >
              <template v-slot:thumb-label="{ value }">
                {{ satisfactionEmojis[Math.min(value, 5)] }}
              </template>
            </v-slider>
          </v-col>
          <v-col cols="12" md="6">
             {{ satisfactionText[Math.min(question.rating, 5)] }}
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-textarea v-model="feedback" outlined label="Есть ли у вас пожелания по нашей работе?"></v-textarea>
            <v-textarea v-model="additionalComments" outlined label="Есть ли что-то, что мы у вас не спросили, но вы хотели бы сказать?"></v-textarea>
          </v-col>
        </v-row>

        <v-btn type="submit" color="primary">Отправить</v-btn>
      </v-form>
      <v-card v-else>
        <v-card-text>
          {{ message }}
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import feedbackApi from '@/api/feedback';
export default {
  props: ['encoded_str'],
  data() {
    return {
      isForm: false,
      message: 'Загрузка формы',
      fullName: '',
      email: '',
      satisfactionEmojis: ['😭', '🙁', '🙂', '😄', '😍'],
      satisfactionText: [
          'Полностью неудовлетворительно, необходимо принимать меры',
          'Не доволен, но хочу дать шанс исправиться',
          'Нормально',
          'Хорошо',
          'Выше всяких похвал',
      ],
      sourceFullName: 'Предзаполненное ФИО Первоисточника',
      sourceEmail: 'Предзаполненная Почта Первоисточника',
      sourceProject: 'Предзаполненный Проект Первоисточника',
      sourceDate: '',
      questions: [
        { label: 'Соблюдение дедлайнов, скорость ответов', rating: 2 },
        { label: 'Активность, инициативность', rating: 2 },
        { label: 'Качество коммуникации аккаунта', rating: 2 },
        { label: 'Уровень экспертизы', rating: 2 },
        { label: 'Довольны ли вы результатами РК', rating: 2 },
      ],
      feedback: '',
      additionalComments: '',
    };
  },
  methods: {
    userProjects() {
      feedbackApi.isValid(this.encoded_str)
        .then(response => {
          if (response.data.exists === false) {
            this.isForm = true
            this.message = ''
          }
          else {
            this.isForm = false
            this.message = 'Форма уже была заполнена ранее.'
          }
        })
        .catch(error => {
          this.isForm = false
          this.message = 'Ошибка при запросе.'
          console.error('Ошибка при запросе:', error);
        });
    },
    submitForm() {
      const dataForm = {
        uniqueURL: this.encoded_str,
        fullName: this.fullName,
        email: this.email,
        sourceFullName: this.sourceFullName,
        sourceEmail: this.sourceEmail,
        sourceProject: this.sourceProject,
        sourceDate: this.sourceDate,
        questions: this.questions,
        feedback: this.feedback,
        additionalComments: this.additionalComments,
      }
      feedbackApi.setAnswer(dataForm)
        .then(response => {
          this.isForm = !response.data.success
          this.message = response.data.message
        })
        .catch(error => {
          console.error('Ошибка при запросе:', error);
        });
    },
    decodeBase64(encodedStr) {
      try {
        const decodedStr = decodeURIComponent(escape(atob(encodedStr)));
        return JSON.parse(decodedStr);
      } catch (error) {
        console.error('Error decoding base64:', error);
        return null;
      }
    },
    prefetchData(decodedData) {
      this.sourceFullName = decodedData['n']
      this.sourceEmail = decodedData['e']
      this.sourceProject = decodedData['p']
      this.sourceDate = decodedData['d']
    }
  },
  mounted() {
    this.prefetchData(this.decodeBase64((this.encoded_str).replaceAll('%FE', '/')))
    this.userProjects()
  },
};
</script>
