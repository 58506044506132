import api from '../api/campaigns'
import calc from '../utils/calc'

// const headers = {
//   campaign_id: 'Идентификатор',
//   campaign_name: 'Имя',
//   clicks: 'Клики',
//   cost: 'Расходы',
//   impressions: 'Показы',
//   bounces: 'Отказы',
//   report_date: 'Дата',
//   device: 'Устройство',
//   avg_impression_position: 'Cредняя позиция показа',
//   avg_click_position: 'Cредняя позиция клика',
//   avg_traffic_volume: 'Cредний объем трафика',
// }

let blacklist = [
        'ad_id',
        'campaign_id',
        'account_login',
        'report_date',

        'Id',
        'CampaignId',
        "AdGroupId",
        "Status",
        "State",
        "Subtype",
        "Type",
        "StatusClarification",
        "AdCategories",
        "AgeLabel",
    ]

const initialState = {
  campaignID: null,
  ads: [],
  header: [],
  isLoading: true,
  isLoadingAds: true,
  showColumnsDefault: [],
  showColumns: [],
  total: {},
}

const getters = {
  campaignID: state => state.campaignID,
  ads: state => state.ads,
  header: state => state.header,
  isLoading: state => state.isLoading,
  isLoadingAds: state => state.isLoadingAds,
  showColumnsDefault: state => state.showColumnsDefault,
  total: state => state.total,
  headerText: state => state.headerText,
  showColumns: state => state.showColumns,
  showHeader: state => state.header.filter((col) => state.showColumns.includes(col.value)),
}

const actions = {
  prefetchCampaign({ commit, getters, rootGetters }, campaignID) {
    return api.campaign(campaignID, rootGetters['user/activeAccountLogin'])
      .then(response => {
        const { header, shown_by_default, header_text, ads } = response['data']['ads']
        let header_obj = []
        header.forEach(col => header_obj.push({
          text: header_text[col] || col,
          value: col,
        }))
        let shown_by_default_obj = []
        shown_by_default.forEach(col => shown_by_default_obj.push({
          text: header_text[col] || col,
          value: col,
        }))
        commit('setAdsHeader', header_obj)
        commit('setAds', ads)
        commit('setTotal', calc.calc_total(ads))
        commit('setHeaderText', header_text)
        commit('setShowColumnsDefault', shown_by_default_obj)
        if (getters.showColumns.length < 1)
          commit('setShowColumns', shown_by_default_obj.map((item)=>item.value))
        commit('isLoading', false)
      })
      .catch(e => {
        commit('isLoading', false)
        console.log(e)
      })
  },

  prefetchAds({ commit, getters, rootGetters }, campaignID) {
    return api.ad(campaignID || getters.campaignID, rootGetters['user/activeAccountLogin'])
      .then(response => {
        const { ads } = response.data
        let adsState = getters.ads
        for (let i = 0; i < adsState.length; i++) {
          adsState[i] = {...adsState[i], ...ads[adsState[i].ad_id]}
          delete ads[adsState[i].ad_id]
        }
        // Object.values(ads).forEach(val => adsState.push(val)) Добавляем объявления, которых не было в базе clickhouse
        let header = Object.keys(adsState[0])
        let header_text = getters.headerText
        let header_obj = []
        // header.forEach(col => header_obj.push({
        //   text: header_text[col] || col,
        //   value: col,
        // }))
        for (let i = 0; i < header.length; i++) {
          let col = header[i]
          if (!blacklist.includes(col))
            header_obj.push({
              text: header_text[col] || col,
              value: col,
            })
        }
        let showColumns = getters.showColumns
        showColumns.push('PreviewUrl')
        showColumns.push('ThumbnailUrl')

        commit('setAds', adsState)
        commit('setAdsHeader', header_obj)
        commit('setShowColumns', showColumns)
        commit('isLoadingAds', false)
      })
      .catch(e => {
        commit('isLoadingAds', false)
        console.log(e)
      })
  },
  reset({ commit }) {
      commit('isLoadingAds', true)
      commit('isLoading', true)
  }
}

const mutations = {
  isLoading (state, val) {
    state.isLoading = val
  },
  isLoadingAds (state, val) {
    state.isLoadingAds = val
  },
  setCampaignID (state, val) {
    state.campaignID = val
  },
  setAdsHeader (state, val) {
    state.header = val
  },
  setHeaderText (state, val) {
    state.headerText = val
  },
  setAds (state, val) {
    state.ads = val
  },
  setTotal (state, val) {
    state.total = val
  },
  setShowColumnsDefault (state, val) {
    state.showColumnsDefault = val
  },
  setShowColumns (state, val) {
    state.showColumns = val
  },
}

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
}
