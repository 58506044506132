import session from './session'

export default {
  isValid(uu) {
    const encodedUU = encodeURIComponent(uu);
    return session.get(`/api/v1/feedback/?unique_url=${encodedUU}`, {maxRedirects: 0})
  },
  setAnswer(data) {
    return session.post('/api/v1/feedback/', data)
  },
}
