import auth from '../api/auth'
import session from '../api/session'
import Cookies from 'js-cookie'

const TOKEN_STORAGE_KEY = 'TOKEN_STORAGE_KEY'
const isProduction = process.env.NODE_ENV === 'production'

const initialState = {
  authenticating: false,
  error: false,
  errorText: '',
  token: null,
}

const getters = {
  isAuthenticated: state => !!state.token || Cookies.get('isAuthenticated'),
  isError: state => state.error,
  errorText: state => state.errorText,
}

const actions = {
  login({ commit, dispatch }, { username, password }) {
    commit('loginBegin')
    return auth.login(username, password)
      .then(({ data }) => {
        commit('setToken', data.key)
        Cookies.set('isAuthenticated', true, { expires: 14 })
        commit('loginSuccess')
        dispatch('user/prefetchUserProjects', {}, {root:true})
      })
      .catch(({code}) => commit('loginFailure', code))
  },
  logout({ commit, dispatch }) {
    return auth.logout()
      .then(() => {
        commit('logout')
        Cookies.remove('isAuthenticated')
        dispatch('user/resetUserProjects', {}, {root:true})
      })
      .finally(() => commit('removeToken'))
  },
  initialize({ commit }) {
    const token = localStorage.getItem(TOKEN_STORAGE_KEY)

    if (isProduction && token) {
      commit('removeToken')
    }

    if (!isProduction && token) {
      commit('setToken', token)
    }
  },
}

const mutations = {
  loginBegin (state) {
    state.authenticating = true
    state.errorText = ''
    state.error = false
  },
  loginFailure (state, codeError) {
    state.authenticating = false
    state.errorText = (codeError === 'ERR_BAD_RESPONSE') ? 'Внутренняя ошибка, попробуйте повторить позднее' : (codeError === 'ERR_BAD_REQUEST') ? 'Неверный логин или пароль' : 'Непредвиденная ошибка, попробуйте повторить позднее'
    state.error = true
  },
  loginSuccess (state) {
    state.authenticating = false
    state.errorText = ''
    state.error = false
  },
  logout (state) {
    state.authenticating = false
    state.errorText = ''
    state.error = false
  },
  setToken (state, token) {
    if (!isProduction) localStorage.setItem(TOKEN_STORAGE_KEY, token)
    session.defaults.headers.Authorization = `Token ${token}`
    state.token = token
  },
  removeToken (state) {
    localStorage.removeItem(TOKEN_STORAGE_KEY)
    delete session.defaults.headers.Authorization
    state.token = null
  },
}

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
}
