import apiReport from '../api/report'

const initialState = {
  embedToken: null,
  embedUrl: null,
}

const getters = {
  embedToken: state => state.embedToken,
  embedUrl: state => state.embedUrl,
}
const actions = {
  prefetchConfigProject({ commit, rootGetters }) {
    if(rootGetters['auth/isAuthenticated'])
      return apiReport.config(rootGetters['user/activeProject'])
        .then(( {data} ) => {
          commit('setEmbedToken', data.EmbedToken.Token)
          commit('setEmbedUrl', data.EmbedUrl)
        }).catch(() => {
          commit('setEmbedToken', null)
          commit('setEmbedUrl', null)
      })
  },
}

const mutations = {
  setEmbedToken (state, val) {
    state.embedToken = val
  },
  setEmbedUrl (state, val) {
    state.embedUrl = val
  },
}

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
}
