import session from './session'

export default {
  isAuthenticated() {
    return 'id' in Object.keys(session.get('/api/auth/user/'))
  },
  login(username, password) {
    return session.post('/api/auth/login/', { username, password })
  },
  logout() {
    return session.post('/api/auth/logout/', {})
  },
}
