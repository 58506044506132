<template>
  <v-card color="basil" :loading="isLoading">
    <v-alert
      border="left"
      colored-border
      elevation="2"
      type="info"
      v-show="isLoading"
    >
      Загрузка...
    </v-alert>
    <v-alert
      border="left"
      colored-border
      elevation="2"
      type="info"
      v-show="isNone"
    >
      Нет отчета для отображения
    </v-alert>
    <v-card
      color="basil"
      flat
      v-show="(!!reportUrl) && (errorPrefetchReport || isLoading)"
    >
      <v-card-text>
        <div style="overflow: hidden;">
          <div style="margin-bottom: -44px">
            <iframe :src="reportUrl" width="100%" style="height:48vw"></iframe>
          </div>
        </div>
      </v-card-text>
    </v-card>
    <v-card
      color="basil"
      flat
      v-show="!errorPrefetchReport"
    >
      <v-card-text>
        <div style="overflow: hidden;">
          <div style="margin-top: -40px">
            <section
              id="report_iframe"
              style="height:48vw"
            />
          </div>

        </div>
      </v-card-text>
    </v-card>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import * as pbi from "powerbi-client";
export default {
  data: () => ({
    tab: null,
    projects:[],
    isLoading:true,
    errorPrefetchReport:false,


  }),
  computed: {
    ...mapGetters({
      reportUrl: 'user/reportUrl',
      embedToken: 'report/embedToken',
      embedUrl: 'report/embedUrl',
      activeAccountLogin: 'user/activeAccountLogin'
    }),
    isNone(){
      return (!this.reportUrl) && (!this.isLoading) && this.errorPrefetchReport
    },
  },
  methods: {
    ...mapActions({
        prefetchConfigProject: 'report/prefetchConfigProject',
      }),
    mountedBI() {
      this.errorPrefetchReport = false
      this.isLoading = true
      this.prefetchConfigProject().then(() => {
        const dashboardContainer = document.getElementById("report_iframe")
        let powerbi = new pbi.service.Service(
                  pbi.factories.hpmFactory,
                  pbi.factories.wpmpFactory,
                  pbi.factories.routerFactory
          )
        powerbi.reset(dashboardContainer)
        if (this.embedToken && this.embedUrl) {
          const permissions = pbi.models.Permissions.All

          const config = {
            type: "report",
            tokenType: pbi.models.TokenType.Embed,
            accessToken: this.embedToken,
            embedUrl: this.embedUrl,
            pageView: "fitToWidth",
            permissions: permissions,
          }

          const dashboard = powerbi.embed(dashboardContainer, config)

          dashboard.off("loaded");
          dashboard.off("rendered")
          dashboard.on("error", function () {
            this.dashboard.off("error")
          })
        }
        else {
          this.errorPrefetchReport = true
          powerbi.reset(dashboardContainer)
        }
        this.isLoading = false
      })
    },
  },
  mounted() {
    this.mountedBI()
  },
  watch:{
     activeAccountLogin() {this.mountedBI()},
  },
}
</script>