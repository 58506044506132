<template>
  <div>
    <v-skeleton-loader
      v-if="isLoading"
      type="table-heading, list-item-two-line"
    ></v-skeleton-loader>
    <div v-else>
      <v-row style="margin-bottom: 0;">
        <v-col
          cols="1"
        >
          <v-avatar
            color="primary"
            size="48"
            @click="redirect('campaigns')"
            style="cursor: pointer"
          >
            <v-icon dark>
              mdi-arrow-left-circle
            </v-icon>
          </v-avatar>
        </v-col>
        <v-col
          cols="11"
        >
          <v-autocomplete
            v-model="showColumns"
            :items="header"
            dense
            chips
            small-chips
            label="Показатели"
            multiple
            :loading="isLoadingAds"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-data-table
        dense
        :headers="showHeader"
        :items="ads"
        item-key="name"
        class="elevation-1"
        :items-per-page="25"
        :footer-props="{itemsPerPageOptions:[15, 25, 50, 100]}"
      >
        <template v-slot:item.cost="{ item }">
          {{item.cost.toFixed(2)}}&nbsp;₽
        </template>
        <template v-slot:item.bounces_percent="{ item }">
          {{item.bounces_percent.toFixed(0)}}%
        </template>
        <template v-slot:item.PreviewUrl="{ item }">
          <div class="img_block">
            <img
              style="max-height: 50px"
              class="pointer"
              :id="'img_'+item.ad_id"
              :src="item.PreviewUrl"
              @click="openDialog(item)"
              @load="(event) => {scopeSize(event)}"
              alt=""/>
            <span></span>
          </div>
        </template>
        <template v-slot:item.ThumbnailUrl="{ item }">
          <div class="img_block">
             <img
              style="max-height: 50px"
              class="pointer"
              :src="item.ThumbnailUrl"
              @click="openDialog(item.ThumbnailUrl)"
              @load="(event) => {scopeSize(event)}"
              alt=""/>
            <span></span>
          </div>
        </template>
        <template v-slot:no-data>
          Нет объявлений для отображения
        </template>
        <template slot="body.append">
          <tr>
            <th
                v-for="(col, index) in showHeader"
                v-bind:key="col.value"
            >
              <span v-if="index === 0">Итого </span>{{total[col.value]}}
            </th>
          </tr>
        </template>
      </v-data-table>

      <v-dialog
        v-model="dialog"
        width="800px"
      >
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text class="text-center">
            <img
              style="max-height: 85vh; max-width: 100%;"
              :src="previewUrl"
              alt=""/>
          </v-card-text>
        </v-card>
      </v-dialog>

    </div>
  </div>
</template>


<script>
  import { mapGetters, mapActions } from 'vuex'
  export default {
    name: 'campaign',
    data: () => ({
      dialog: false,
      previewUrl: null,
    }),
    created() {
      this.prefetchCampaign(this.id)
      this.prefetchAds(this.id)
    },
    methods: {
      ...mapActions({
        prefetchCampaign: 'campaign/prefetchCampaign',
        prefetchAds: 'campaign/prefetchAds',
        reset: 'campaign/reset',
      }),
      redirect(value) {
        this.$router.push({ name: value})
      },
      openDialog(url) {
        if (url) {
          this.previewUrl = url
          this.dialog = true
        }
      },
      closeDialog() {
        this.previewUrl = null
        this.dialog = false
      },
      scopeSize(e) {
        let img = e.target
        img.nextSibling.innerHTML = img.naturalWidth + " x " + img.naturalHeight + " px"
      },
    },
    computed: {
      ...mapGetters({
        ads: 'campaign/ads',
        total: 'campaign/total',
        header: 'campaign/header',
        showHeader: 'campaign/showHeader',
        isLoading: 'campaign/isLoading',
        isLoadingAds: 'campaign/isLoadingAds',
        activeAccountLogin: 'user/activeAccountLogin'
      }),
      id() {
        return this.$route.params.id;
      },
      showColumns: {
        get() {
          return this.$store.getters['campaign/showColumns']
        },
        set(value) {
          this.$store.commit('campaign/setShowColumns', value)
        },
      }
    },
    watch: {
      activeAccountLogin(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.$router.push({name: 'campaigns'})
        }
      }
    },
    destroyed() {
      this.reset()
    }
  }
</script>