<template>
  <div
    style="background-color: #46AFEB; min-height: 100vh; padding-top: 2em;"
  >
    <v-container style="background-color: #FFF">
      <h1>404 Страница не найдена</h1>
      <p>Извините, страницы для "{{ notFoundPath }}" не существует.</p>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
  computed: {
    notFoundPath() {
      return this.$router.history.current.path;
    },
  },
};
</script>