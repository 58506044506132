<template>
  <v-app>
    <div id="app">
      <div v-if="isAuth">
      <v-navigation-drawer
              class="primary"
              height="100%"
        dark
        permanent
              fixed
              clipped
      >
          <img :src="logo" :alt="agency" width="160px" class="ma-6">

        <v-list
          dense
          rounded
        >
          <div class="ml-4">
            <h2>{{userClients}}</h2>
            <v-select
              v-if="isMultiProject"
              v-model="activeProject"
              :items="userProjects"
            ></v-select>
            <p
              v-else
              v-text="userProjects[0] ? userProjects[0].text : 'Нет проектов'"
            >
            </p>
          </div>
         <!-- <v-list-item
            disabled
          >
            <v-list-item-icon>
              <v-icon>
                mdi-chart-bar
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                Статистика
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>-->

          <v-list-item to="/">
            <v-list-item-icon>
              <v-icon>
                mdi-view-dashboard
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                Кампании
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/report">
            <v-list-item-icon>
              <v-icon>
                mdi-file-chart
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                Отчеты
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/account">
            <v-list-item-icon>
              <v-icon>
                mdi-account
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                Мой аккаунт
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <div
          v-if="showOnegaCoefficient"
          style="padding: 1.5em"
        >
          <div style="display: flex; justify-content: space-between">
            <v-icon>
              mdi-account-check
            </v-icon>
            <v-icon>
              mdi-account-group
            </v-icon>
          </div>
          <v-slider
            v-model="onegaCoefficient"
            step="1"
            max="2"
            ticks="always"
            tick-size="5"
            :tick-labels="onegaCoefficients"
          ></v-slider>
          <v-btn
            v-if="isOnegaCoefficientChanged"
            @click="saveOnegaCoefficient"
          >
            Сохранить
          </v-btn>
        </div>
      </v-navigation-drawer>
      <main style="padding: 30px 40px 80px 300px;">
        <router-view/>
      </main>
      </div>
      <div v-else><router-view/></div>
      </div>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'app',
  data: () => ({
    onegaCoefficients:['Качественно', '', 'Много'],
  }),
  computed: {
    ...mapGetters({
      isAuth: 'auth/isAuthenticated',
      userProjects: 'user/userProjectItems',
      userClients: 'user/userClientsName',
      isMultiProject: 'user/isMultiProject',
      agency: 'user/agency',
      logo: 'user/logo',
      color: 'user/color',
      light: 'user/light',
      showOnegaCoefficient: 'user/showOnegaCoefficient',
      isOnegaCoefficientChanged: 'user/isOnegaCoefficientChanged',
    }),
    onegaCoefficient: {
       get() {
        return this.$store.getters['user/onegaCoefficientTemp']
      },
      set(val) {
        this.$store.commit('user/setOnegaCoefficientTemp', val)
      }
    },
    activeProject: {
      get() {
        return this.$store.getters['user/activeProject']
      },
      set(val) {
        this.$store.commit('user/setActiveProject', val)
      }
    }
  },
  methods: {
    ...mapActions({
      prefetchUserProjects: 'user/prefetchUserProjects',
      saveOnegaCoefficient: 'user/saveOnegaCoefficient'
    })
  },
  created() {
    this.prefetchUserProjects()
  },
  watch:{
   color() {this.$vuetify.theme.themes.light.primary = this.color},
  }
}
</script>
