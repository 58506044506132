
const initialState = {
  formData: {
    fullName: '',
    email: '',
    sourceFullName: 'Предзаполненное ФИО Первоисточника',
    sourceEmail: 'Предзаполненная Почта Первоисточника',
    sourceProject: 'Предзаполненный Проект Первоисточника',
    questions: [
      { label: 'Соблюдение дедлайнов, скорость ответов', rating: 3 },
      { label: 'Активность, инициативность', rating: 3 },
      { label: 'Качество коммуникации аккаунта', rating: 3 },
      { label: 'Уровень экспертизы', rating: 3 },
    ],
    feedback: '',
    additionalComments: '',
  },
}
const mutations = {
  updateFormData(state, { field, value }) {
    state.formData[field] = value
  },
}
const actions = {
  submitForm({ state }) {
    // Здесь можно выполнить дополнительные действия перед отправкой данных, например, вызов API
    console.log('Отправка данных:', state.formData)
    // После отправки данных можно сбросить форму
    // this.commit('resetForm');
  },
  // Если необходимо добавить дополнительные действия, добавьте их здесь
}
const getters = {
  getFormData: (state) => state.formData,
}

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
}
