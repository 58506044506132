<template>
  <v-form
    v-model="valid"
    v-on:submit.prevent="submitForm"
    @keydown.native.enter.prevent="login"
    style="background-color: #46AFEB; min-height: 100vh"
  >
    <v-container>
      <div class="mb-10 mt-10 logo_block">
        <div><img src="@/assets/logo.svg" alt="media 108" width="160px" class="ma-6"></div>
        <div><img src="@/assets/logo_1ga.png" alt="1ga" width="160px" class="ma-6"></div>
      </div>
      <v-card
        class="mx-auto"
        max-width="700"
      >
        <v-card-text>
          <h2>Вход в личный кабинет</h2>
          <p></p>
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="userName"
                label="Логин"
                :error="isError"
                :error-messages="errorText"
                required
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="password"
                :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show3 ? 'text' : 'password'"
                :error="isError"
                label="Пароль"
                class="input-group--focused"
                @click:append="show3 = !show3"
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
           <v-spacer></v-spacer>
          <v-btn
            outlined
            rounded
            text
            @click="login"
          >
            Вход
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </v-form>
</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    data: () => ({
      show3: false,
      valid: false,
      userName: '',
      password: '',
    }),
    computed: {
      ...mapGetters({
        isError: 'auth/isError',
        errorText: 'auth/errorText',
      }),
    },
    methods: {
      login() {
        this.$store.dispatch('auth/login', {username: this.userName, password: this.password})
          .then(() => this.$router.push('/'))
      },
    },
  }
</script>
