<template>
  <div>
    <v-skeleton-loader
      v-if="isLoading"
      type="table-heading, list-item-two-line"
    ></v-skeleton-loader>
    <div v-else>
      <v-row>
        <v-col
          cols="6"
          sm="2"
        >
          <v-menu
            ref="menuFrom"
            v-model="menuFrom"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"

          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="fromDate"
                label="От"
                persistent-hint
                prepend-icon="mdi-calendar"
                v-bind="attrs"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="fromDate"
              no-title
              :min="min_date"
              :max="toDate"
              @input="menuFrom = false"
            ></v-date-picker>
          </v-menu>

        </v-col>
        <v-col
          cols="6"
          sm="2"
        >
          <v-menu
            ref="menuTo"
            v-model="menuTo"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"

          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="toDate"
                label="До"
                persistent-hint
                prepend-icon="mdi-calendar"
                v-bind="attrs"
                readonly
                v-on="on"
                append-outer-icon="mdi-check"
                @click:append-outer="prefetchCampaigns"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="toDate"
              no-title
              :min="fromDate"
              @input="menuTo = false"
            ></v-date-picker>
          </v-menu>

        </v-col>
        <v-col
          cols="12"
          sm="8"
        >
          <v-autocomplete
            v-model="showColumns"
            :items="header"
            dense
            chips
            small-chips
            label="Показатели"
            multiple
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-data-table
        dense
        :headers="showHeader"
        :items="campaigns"
        item-key="name"
        class="elevation-1"
        :items-per-page="25"
        :footer-props="{itemsPerPageOptions:[15, 25, 50, 100]}"
      >
        <template v-slot:item.more="{ item }">
          <v-icon
            @click="handleClick(item)"
          >
            mdi-dots-horizontal-circle
          </v-icon>
        </template>
        <template v-slot:item.cost="{ item }">
          {{item.cost.toFixed(2)}}&nbsp;₽
        </template>
        <template v-slot:item.bounces_percent="{ item }">
          {{item.bounces_percent.toFixed(0)}}%
        </template>
        <template slot="body.append">
          <tr>
            <th
                v-for="(col, index) in showHeader"
                v-bind:key="col.value"
            >
              <span v-if="index === 0">Итого </span>{{total[col.value]}}
            </th>
          </tr>
        </template>
      </v-data-table>
    </div>
  </div>
</template>


<script>
  import { mapGetters, mapActions } from 'vuex'
  export default {
    data: () => ({
      menuFrom: false,
      menuTo: false,
    }),
    mounted() {
      this.prefetchCampaigns()
    },
    methods: {
      ...mapActions({
        prefetchCampaigns: 'campaigns/prefetchCampaigns',
      }),
      handleClick(value) {
        this.$router.push({ name: 'campaign', params: { id: value.campaign_id } })
      },
    },
    computed: {
      ...mapGetters({
        campaigns: 'campaigns/campaigns',
        header: 'campaigns/header',
        total: 'campaigns/total',
        showHeader: 'campaigns/showHeader',
        isLoading: 'campaigns/isLoading',
        activeAccountLogin: 'user/activeAccountLogin',
      }),
       min_date() {
        let min_date = new Date()
         min_date.setYear(min_date.getFullYear()-1)
         return min_date.toISOString()
      },
      fromDate: {
        get() {
          return this.$store.getters['campaigns/fromDate']
        },
        set(value) {
          this.$store.commit('campaigns/setFromDate', value)
        },
      },
      toDate: {
        get() {
          return this.$store.getters['campaigns/toDate']
        },
        set(value) {
          this.$store.commit('campaigns/setToDate', value)
        },
      },
      showColumns: {
        get() {
          return this.$store.getters['campaigns/showColumns']
        },
        set(value) {
          this.$store.commit('campaigns/setShowColumns', value)
        },
      },
    },
    watch:{
       activeAccountLogin() {this.prefetchCampaigns()},
    }
  }
</script>