import session from './session'

export default {
  userProjects() {
    return session.get('/api/v1/user/', {maxRedirects: 0})
  },
  setCoefficient(periodicTasksID, coefficient) {
    return session.post('/api/v1/rtb_coefficient/' + periodicTasksID + '/', { coefficient })
  },
}
