import Vue from 'vue'
import Vuex from 'vuex'
import createLogger from 'vuex/dist/logger'

import auth from './auth'
import user from './user'
import campaigns from './campaigns'
import campaign from './campaign'
import report from './report'
import feedback from './feedback'

const debug = process.env.NODE_ENV !== 'production'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    user,
    campaigns,
    campaign,
    report,
    feedback
  },
  strict: debug,
  plugins: debug ? [createLogger()] : [],
})
