import user from '../api/user'

const initialState = {
  userClients: [],
  userProjects: [],
  activeProject: null,
  activeAccountLogin: null,
  reports: {},
  agency: 'Media 108',
  logo: '/static/img/logo.ed4bf4e9.svg',
  color: '#46AFEB',
  light: false,
  onegaCoefficient: 0,
  onegaCoefficientTemp: 0,
  periodicTasksID: null,
}

const getters = {
  userProjects: state => state.userProjects,
  userClients: state => state.userClients,
  agency: state => state.agency,
  logo: state => state.logo,
  color: state => state.color,
  light: state => state.light,
  isUserProjects: state => state.userProjects && (state.userProjects.length > 0),
  isMultiProject: state => state.userProjects && (state.userProjects.length > 1),
  userProjectsNames: (state, getters) => getters.isUserProjects ? state.userProjects.map((item) => item.fields.name) : [],
  userProjectItems: (state, getters) => getters.isUserProjects ? state.userProjects.map((item) => ({
    text: item.fields.name,
    value: item.pk,
  })) : [],
  userClientsName: (state, getters) => getters.isUserProjects ? state.userClients[0].fields.name : '',
  activeProject: state => state.activeProject,
  activeAccountLogin: state => state.activeAccountLogin,
  reports: state => state.reports,
  reportUrl: state => state.reports[state.activeProject] || '',
  showOnegaCoefficient: state => state.periodicTasksID !== null,
  onegaCoefficient: state => state.onegaCoefficient,
  onegaCoefficientTemp: state => state.onegaCoefficientTemp,
  isOnegaCoefficientChanged: state => state.onegaCoefficient != state.onegaCoefficientTemp,
  periodicTasksID: state => state.periodicTasksID,
}

const actions = {
  prefetchUserProjects({ commit, getters, rootGetters }) {
    if(rootGetters['auth/isAuthenticated'] && getters.userProjects.length < 1)
    return user.userProjects()
      .then(( {data} ) => {
        let projects = data.projects
        let reports = {}
        projects.forEach(project => {
          reports[project.pk] = project.fields.bi
        })
        commit('setUserClients', data.clients)
        commit('setUserProjects', projects)
        commit('setReports', reports)
        if (getters.activeProject === null)
          commit('setActiveProject', (data.projects && data.projects.length > 0) ? data.projects[0].pk : null)
        commit('setAgency', data.agency.name)
        commit('setLogo', data.agency.logo)
        commit('setColor', data.agency.color)
        commit('setLight', data.agency.light)
        let coefficient = data.agency.coefficient
        if (coefficient) {
          commit('setPeriodicTasksID', coefficient.periodic_tasks_id)
          commit('setOnegaCoefficient', coefficient.current_coefficient)
          commit('setOnegaCoefficientTemp', coefficient.current_coefficient)
        }
        else {
          commit('setPeriodicTasksID', null)
        }
      })
      // .catch(() => {
      //   dispatch('auth/logout', {}, {root:true})
      // })
  },
  saveOnegaCoefficient({ commit, getters }) {
    return user.setCoefficient(getters.periodicTasksID, getters.onegaCoefficientTemp).then(( {data} ) => {
      commit('setOnegaCoefficient', data.coefficient)
    })
  },
  resetUserProjects ({ commit }) {
    commit('resetState')
  }
}

const mutations = {
  setUserClients (state, val) {
    state.userClients = val
  },
  setUserProjects (state, val) {
    state.userProjects = val
  },
  setActiveProject (state, val) {
    state.activeProject = val
    state.activeAccountLogin = state.userProjects.filter(project => project.pk === val)[0].fields.account_login
  },
  setReports (state, val) {
    state.reports = val
  },
  setAgency (state, val) {
    state.agency = val
  },
  setLogo (state, val) {
    state.logo = val
  },
  setColor (state, val) {
    state.color = val
  },
  setLight (state, val) {
    state.light = val
  },
  setOnegaCoefficient (state, val) {
    state.onegaCoefficient = val
  },
  setOnegaCoefficientTemp (state, val) {
    state.onegaCoefficientTemp = val
  },
  setPeriodicTasksID (state, val) {
    state.periodicTasksID = val
  },

  resetState (state) {
    state.userClients = []
    state.userProjects = []
    state.activeProject = null
    state.activeAccountLogin = null
    state.reports = {}
  }
}

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
}
