<template>
 <v-card>
    <v-list-item three-line
    >
      <v-list-item-content>
        <div class="mb-4">Клиент</div>
        <div
          v-for="item in clients"
          :key="item.pk"
        >
          <v-list-item-title class="text-h5 mb-1">
            {{item.fields.name}}
          </v-list-item-title>
        </div>
        <div class="mb-4">Проекты</div>
        <div
          v-for="item in projects"
          :key="item.pk"
        >
          <v-list-item-subtitle>
            {{item.fields.name}}
          </v-list-item-subtitle>
        </div>
      </v-list-item-content>

    </v-list-item>

    <v-card-actions>
      <v-btn to="logout">
        Выход
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from 'axios'
export default {
  data: () => ({
    clients:[],
    projects:[],
    isLoading:true,
  }),
  mounted() {
    axios.get('/api/v1/user/')
    .then(response => {
      const {clients, projects} = response['data']
      this.isLoading = false
      this.clients = clients
      this.projects = projects
    })
    .catch(e => {
      this.isLoading = true
      console.log(e)
    })
  },
}
</script>