import session from './session'

export default {
  campaigns(accountLogin, fromDate, toDate) {
    return session.get('/api/v1/campaigns/?account_login='+accountLogin + '&from_date='+fromDate + '&to_date='+toDate)
  },
  campaign(campaign_id, accountLogin) {
    return session.get('/api/v1/campaign/' + campaign_id + '/?account_login='+accountLogin)
  },
  ad(campaign_id, accountLogin) {
    return session.get('/api/v1/extend_ads/' + campaign_id + '/?account_login='+accountLogin)
  },
}
