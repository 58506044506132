import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
import Campaigns from '../views/Campaigns.vue'
import Campaign from '../views/Campaign.vue'
import Report from '../views/Report.vue'
import Account from '../views/Account.vue'
import Login from '../views/Login.vue'
import Feedback from '../views/Feedback.vue'
import NotFound from '../views/NotFound.vue'

Vue.use(VueRouter)

import store from '../store'

const requireAuthenticated = (to, from, next) => {
  store.dispatch('auth/initialize')
    .then(() => {
      if (!store.getters['auth/isAuthenticated']) {
        next('/login')
      } else {
        document.title = to.meta.title
        next()
      }
    })
}

const requireUnauthenticated = (to, from, next) => {
  store.dispatch('auth/initialize')
    .then(() => {
      if (store.getters['auth/isAuthenticated']) {
        next('/')
      } else {
        next()
      }
    })
}

const redirectLogout = (to, from, next) => {
  store.dispatch('auth/logout')
    .then(() => {
      next({ name: 'login' })
    })
}

const checkDataVerification = (to, from, next) => {
  try {
    const decodedData = atob((to.params.encoded_str).replaceAll('%FE', '/'));
    const jsonData = JSON.parse(decodedData);
    if (jsonData && typeof jsonData === 'object') {
      next();
    } else {
      next({ name: 'not-found' });
    }
  }
  catch (error) {
    next({ name: 'not-found' });
  }
}

const routes = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: Home,
  //   beforeEnter: requireAuthenticated,
  // },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      title: 'Авторизация',
    },
    beforeEnter: requireUnauthenticated,
  },
  {
    path: '/',
    name: 'campaigns',
    props: true,
    component: Campaigns,
    meta: {
      title: 'Отчет по кампаниям',
    },
    beforeEnter: requireAuthenticated,
  },
  {
    path: '/campaign/:id?',
    name: 'campaign',
    component: Campaign,
    meta: {
      title: 'Отчет по кампании',
    },
    beforeEnter: requireAuthenticated,
  },
  {
    path: '/report',
    name: 'report',
    component: Report,
    meta: {
      title: 'Отчет',
    },
    beforeEnter: requireAuthenticated,
  },
  {
    path: '/account',
    name: 'account',
    component: Account,
    meta: {
      title: 'Учетная запись',
    },
    beforeEnter: requireAuthenticated,
  },
  {
    path: '/logout',
    name: 'logout',
    beforeEnter: redirectLogout,
  },
  {
    path: '/feedback/:encoded_str',
    name: 'feedback',
    component: Feedback,
    props: true,
    beforeEnter: checkDataVerification,
  },
  {
    path: '*',
    component: NotFound,
    name: 'not-found',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
