export default {
  calc_total(items) {
    let col_name_sum = [
      'clicks',
      'cost',
      'impressions',
      'bounces',
    ]
    let total = {}
    col_name_sum.forEach((col) => total[col] = items.reduce((partialSum, vals) => partialSum + vals[col], 0).toFixed(2))
    return total
  }
}
